<template>
	<div class="tc-permit">
		<!-- 顶部功能 -->
		<div class="header-tool">
			<div class="authorityimg">
				<router-link to="/permit/regulate">
					<img src="../../../assets/images/authorityBtn.png" height="40" alt />
				</router-link>
			</div>
			<div class="authorityimg" @click="showAddDepartmentBox">
				<img src="../../../assets/images/newDepartmentBtn.png" height="42" alt />
			</div>
			<div class="del" @click="delDatas">
				<img src="../../../assets/images/del.png" alt />
			</div>
			<div class="refresh" @click="refresh()">
				<img src="../../../assets/images/refresh.png" alt />
			</div>
		</div>
		<!-- 排序 -->
		<div class="sort">
			<div class="left">
				<el-button type="plain" plain>排序</el-button>
				<el-divider direction="vertical"></el-divider>
				<el-input v-model="search" style="width: 200px" placeholder="查询管理员姓名"></el-input>
				<el-button type="plain" plain @click="loadListDepartData()">查询</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="content">
			<el-table v-loading="loading" :data="tableData" style="width: 100%" align="center"
				@selection-change="handleSelectionChange" @expand-change="rowExpand" :expand-row-keys="expends"
				:row-key="getRowKeys">
				<el-table-column type="selection" width="60"></el-table-column>
				<!--------------------------------------- 二级表格------------------------------------------ -->
				<el-table-column type="expand">
					<template slot-scope="scope">
						<el-table :data="scope.row.admin" style="width: 100%">
							<el-table-column type="index" align="center"></el-table-column>
							<el-table-column label="管理员编号" prop="account" align="center"></el-table-column>
							<el-table-column label="姓名" prop="real_name" align="center"></el-table-column>
							<el-table-column label="性别" prop="sex" align="center">
								<template slot-scope="scope">
									<span>{{ scope.row.sex == 0 ? "女" : "男" }}</span>
								</template>
							</el-table-column>
							<el-table-column label="登入日期" prop="last_login_time" align="center"></el-table-column>
							<el-table-column align="center" label="操作">
								<template slot-scope="scope">
									<!-- <el-button size="mini">登陆</el-button>
                  <el-divider direction="vertical"></el-divider>-->
									<el-button size="mini" @click="delAadminList(scope.row)">删除</el-button>
									<el-divider direction="vertical"></el-divider>
									<el-button size="mini" @click="addAdmin(scope.row, 0)">编辑</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<!-- -------------------------------------------------------------------------------------- -->
				<el-table-column label="科室部门名称" prop="name" align="center"></el-table-column>
				<el-table-column label="管理员" prop="name" align="center">
					<template slot-scope="scope">
						<el-button size="mini" class="off" @click="addAdmin(scope.row, 1)">添加管理员</el-button>
					</template>
				</el-table-column>
				<el-table-column label="排序" prop="sort" align="center">
					<template slot-scope="scope">
						<el-input size="mini" maxlength="2" onkeyup="value=value.replace(/[^\d]/g,'') "
							v-model="scope.row.sort" @blur="updateSort(scope.row)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button size="mini" @click="showSchoolBox(scope.row)">学校报告</el-button>
						<el-divider direction="vertical"></el-divider>
						<el-button size="mini" @click="showGroupBox(scope.row)">团体报告</el-button>
						<el-divider direction="vertical"></el-divider>
						<el-button size="mini" @click="showAddDepartmentBox(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 页码 -->
		<div class="page">
			<el-pagination @current-change="handleCurrentChange" :current-page="1" :page-size="15"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<!-- 添加管理员模态框 -->
		<el-dialog :close-on-click-modal="false" :title="addAdminTilte" :visible.sync="addAdminBox" width="40%" center
			@close="hideAddAdminBox">
			<el-form ref="addAadmin" :rules="rules2" :model="form" label-width="96px" size="mini">
				<el-row>
					<el-col :span="12">
						<el-form-item label="账户名称：" prop="account">
							<span v-if="!isAdminState">{{ form.account }}</span>
							<el-input v-model="form.account" v-if="isAdminState"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="密码：" prop="password" v-if="isAdminState">
							<el-input v-model="form.password"
								onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'') "></el-input>
						</el-form-item>
						<el-form-item label="密码：" v-if="!isAdminState">
							<el-input v-model="form.password" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'') "
								placeholder="不修改,可不填写"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="姓名：" prop="name">
							<el-input v-model="form.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别：" prop="sex">
							<el-select v-model="form.sex">
								<el-option label="男" value="1"></el-option>
								<el-option label="女" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="出生日期：" prop="age">
					<el-date-picker v-model="form.age" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
						:picker-options="pickerOptions0"></el-date-picker>
				</el-form-item>
				<el-form-item label="权限设置：" prop="role_id">
					<el-select v-model="form.role_id" placeholder="请选择">
						<el-option v-for="item in permitList" :key="item.id" :label="item.role_name"
							:value="item.id + ''"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="测评权限：">
          <el-radio-group v-model="form.permitList.permit_review">
            <el-radio label="1">允许设置用户测评权限</el-radio>
            <el-radio label="0">禁止设置用户测评权限</el-radio>
          </el-radio-group>
        </el-form-item>-->
				<!-- <transition name="el-zoom-in-top">
          <el-form-item label="量表权限：" v-if="form.permitList.permit_review =='1'">
            <el-radio-group v-model="form.permitList.permit_index">
              <el-radio label="1">允许设置所有量表权限</el-radio>
              <el-radio label="0">仅对部分量表有权限</el-radio>
            </el-radio-group>
          </el-form-item>
        </transition>-->
				<!-- <transition name="el-zoom-in-top" v-if="form.permitList.permit_review =='1'">
          <el-form-item label="量表权限：" v-if="form.permitList.permit_index ==='0'">
            <div class="table" @change="handleCheckAllChange">
              <table>
                <tr>
                  <th>量表名称</th>
                  <th>使用量表</th>
                  <th>测评结果</th>
                </tr>
                <template v-for="item in scaleData">
                  <tr class="title" :key="item.id" v-if="item.status_measure.length >0 ">
                    <td colspan="3">{{item.classify_name}}</td>
                  </tr>
                  <tr v-for="item2 in item.status_measure" :key="item2.id">
                    <td>{{item2.measure_title}}</td>
                    <td>
                      <el-checkbox-group v-model="checkList">
                        <el-checkbox :label="item2.id" name="a"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                    <td>
                      <el-checkbox-group v-model="checkList2">
                        <el-checkbox :label="item2.id" name="b"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </el-form-item>
        </transition>-->
				<!-- <el-form-item label="电子签名：">
          <el-radio-group v-model="form.resource">
            <el-radio label="暂不启用"></el-radio>
            <el-radio label="启用电子签名"></el-radio>
            <el-radio label="启用手写签名"></el-radio>
            <el-radio label="启用电子加手写签名"></el-radio>
          </el-radio-group>
        </el-form-item>-->
				<el-row>
					<el-col :span="8">
						<el-form-item label="上传头像：">
							<el-upload class="avatar-uploader" action="/file_upload" :show-file-list="false"
								:on-success="
                  (res, file, fileList) => fileUploadApi(res, file, fileList, 1)
                " :before-upload="beforeAvatarUpload" :data="fileData">
								<img v-if="head_url" :src="head_url" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="上传签名：" label-width="120px">
							<el-upload class="avatar-uploader" action="/file_upload" :show-file-list="false"
								:on-success="
                  (res, file, fileList) => fileUploadApi(res, file, fileList, 2)
                " :before-upload="beforeAvatarUpload" :data="fileData">
								<img v-if="sign_url" :src="sign_url" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="备注：">
					<el-input type="textarea" v-model="form.remark"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addAdminBox = false">取 消</el-button>
				<el-button type="primary" @click="addAadminBtn">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 新增科室模态框 -->
		<el-dialog :close-on-click-modal="false" :title="departmentTilte" :visible.sync="addDepartment" width="20%"
			center @close="addDrpReset">
			<el-form ref="addDep" :model="depForm" :rules="rules" label-width="180px" size="mini">
				<el-form-item label="科室名称：" prop="depname">
					<el-input v-model="depForm.depname"></el-input>
				</el-form-item>
				<!-- <el-form-item label="所属部门分类：">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>-->
				<el-form-item label="本部门用户问卷测评功能：">
					<el-radio-group v-model="depForm.permit.questionnaire_state">
						<el-radio :label="1">允许</el-radio>
						<el-radio :label="0">禁止</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="本部门用户查看测试记录：">
					<el-radio-group v-model="depForm.permit.show_test_log">
						<el-radio :label="1">允许</el-radio>
						<el-radio :label="0">禁止</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="本部门用户查看档案信息：">
					<el-radio-group v-model="depForm.permit.show_archives">
						<el-radio :label="1">允许</el-radio>
						<el-radio :label="0">禁止</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="本部门用户编辑用户档案：">
					<el-radio-group v-model="depForm.permit.edit_archives">
						<el-radio :label="1">允许</el-radio>
						<el-radio :label="0">禁止</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="本部门用户允许扫二维码：">
					<el-radio-group v-model="depForm.permit.sweep_qr_code">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="0">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="本部门用户语音读题功能：">
					<el-radio-group v-model="depForm.permit.voice_reading">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="0">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="本部门用户在线咨询功能：">
					<el-radio-group v-model="depForm.permit.online_consulting">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="0">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="允许部门管理员修改此项：">
					<el-radio-group v-model="depForm.permit.modify_option">
						<el-radio :label="1">允许</el-radio>
						<el-radio :label="0">禁止</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否开启科室自定义注册：">
					<el-radio-group v-model="depForm.permit.allow_register">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="0">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- <el-form-item label="团体量表：">
		  <el-select v-model="depForm.permit.statistics_group" multiple collapse-tags placeholder="请选择">
		    <el-option
		      v-for="item in options"
		      :key="item.id"
		      :label="item.measure_title"
		      :value="item.id">
		    </el-option>
		  </el-select>
		</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addDepartment = false">取 消</el-button>
				<el-button type="primary" @click="addDepartmentBtn">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 团体报告筛选 -->
		<el-dialog :close-on-click-modal="false" title="团体报告筛选" :visible.sync="GroupBox" width="30%" center
			@close="groupBoxReset">
			<div class="GroupBox">
				量表名称：
				<el-select v-model="groupMeasureId">
					<el-option :label="item.measure_title" :value="item.id" v-for="(item, key) in scaleList"
						:key="key"></el-option>
				</el-select>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="GroupBox = false">取 消</el-button>
				<el-button type="primary" @click="goDownpdfGroup()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 学校报告筛选 -->
		<el-dialog :close-on-click-modal="false" title="学校报告筛选" :visible.sync="SchoolBox" width="30%" center
			@close="schoolBoxReset">
			<div class="GroupBox">
				量表名称：
				<el-select v-model="schoolMeasureId" multiple collapse-tags>
					<el-option :label="item.measure_title" :value="item.id" v-for="(item, key) in schoolList"
						:key="key"></el-option>
				</el-select>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="SchoolBox = false">取 消</el-button>
				<el-button type="primary" @click="goDownpdfSchool()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import axios from "axios";
	import {
		loadDepartmentData
	} from "@/api/comm.js";
	import {
		loadListDepart,
		loadListPermit,
		delAadmin,
		createdDepartment,
		getDepartment,
		updateDepartment,
		getAadminInfo,
		updateAdmin,
		deleteDepartment,
		loadMeasureList,
	} from "@/api/evaluate.js";
	import {
		register
	} from "@/api/comm.js";
	import {
		log
	} from "util";
	export default {
		inject: ["reload"],
		data() {
			return {
				// *************校验**********
				rules: {
					// ***部门模态框***
					depname: [{
						required: true,
						message: "请输入科室名称",
						trigger: "blur"
					}, ],
				},
				// ****校验-添加管理员模态框***
				rules2: {
					account: [{
						required: true,
						message: "请输入账号名称",
						trigger: "blur"
					}, ],
					sex: [{
						required: true,
						message: "请选择性别",
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: "请输入密码",
						trigger: "blur"
					}],
					age: [{
						required: true,
						message: "请选择出生日期",
						trigger: "blur"
					}],
					name: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur"
					}],
					role_id: [{
						required: true,
						message: "请选择权限",
						trigger: "blur"
					}],
				},
				// ***********************
				// 筛选团队报告框
				GroupBox: false,
				groupMeasureId: "",
				groupDepartmentId: "",
				scaleList: [],
				// 加载等待
				loading: true,
				// 表格数据
				tableData: [],
				// 页码
				page: 1,
				size: 15,
				// 总数
				total: 0,
				multipleSelection: [],
				search: "",
				// 添加管理表单
				form: {
					id: 0,
					type: 2,
					account: "",
					password: "",
					name: "",
					sex: "",
					age: "",
					signatureType: 1,
					remark: "",
					permitList: {
						permit_review: "1",
						permit_measure: [],
						permit_index: "1",
					},
					sign_id: "",
					head_id: "",
					departments_id: "",
					role_id: "",
				},
				addAdminBox: false,
				sign_url: "",
				head_url: "",
				fileData: {
					type: 0,
					attribution_id: 1, // 当前 用户的id
				},
				fileData2: {
					type: 1,
					attribution_id: 1, // 当前 用户的id
				},
				// 部门列表
				permitList: [],
				// 所有量表-是否使用
				checkList: [],
				// 所有量表-是否有查看权限
				checkList2: [],
				// 所有量表数据渲染
				scaleData: [],
				// 所有量表数据存储
				scaleData1: [],
				//---- 添加科室-----
				departmentTilte: "新增科室",
				addDepartment: false,
				depForm: {
					depname: "",
					permit: {
						show_test_log: 1,
						show_archives: 1,
						edit_archives: 1,
						sweep_qr_code: 0,
						voice_reading: 0,
						online_consulting: 0,
						modify_option: 1,
						allow_register: 1,
						questionnaire_state: 1,
						statistics_group: []
					},
					parent_id: 0,
					path: 0,
					dId: 0,
				},
				// --------科室-控制修改0、添加1-----------
				isState: 1,
				//--------- 管理员控制 修改0 添加1---------
				isAdminState: 1,
				addAdminTilte: "添加管理员",
				// 日期限制
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() > Date.now() - 8.64e6;
					},
				},
				expends: [],
				expendsTan: [],
				schoolList: [], // 学校报告量表
				schoolDepartmentId: '',
				SchoolBox: false,
				schoolMeasureId: [],
				options: [],
				schoolName: '',
			};
		},
		created() {
			this.loadListDepartData();
			// 获取所有部门
			loadListPermit().then((res) => {
				this.permitList = res.data.data;
			});
			// 团体量表数据
			this.loadScaleData();
			// 学校量表数据
			this.getSchool();
		},
		methods: {
			// 刷新
			refresh() {
				this.reload();
			},
			loadListDepartData(page) {
				this.expends = [];
				let data = {
					size: this.size,
					page: page || 1,
					order: "sort desc",
				};
				if (this.search) {
					Object.assign(data, {
						condition: {
							"%-real_name": this.search,
						},
					});
				}
				loadListDepart(data).then((res) => {
					//console.log(res);
					this.tableData = res.data.data;
					this.total = res.data.total;
					this.loading = false;
					if (this.search != "") {
						this.getExpends();
					}
				});
			},
			// 页码监听
			handleCurrentChange(val) {
				this.page = val;
				// 根据page加载对应数据
				this.loadListDepartData(val);
			},
			// 全选
			handleSelectionChange(val) {
				this.multipleSelection = val;
				let p = [];
				this.multipleSelection.forEach((v) => {
					p.push(v.id);
				});
				this.multipleSelection = p;
			},
			// 显示添加管理员模态框    /    修改
			addAdmin(row, val) {
				this.isAdminState = val;
				//console.log(val);
				this.addAdminBox = true;
				if (val === 1) {
					this.addAdminTilte = "添加管理员";
					this.form.departments_id = row.id + "";
				} else {
					this.addAdminTilte = "编辑管理员信息";
					let id = row.id;
					// 数据填充
					getAadminInfo({
						id
					}).then((res) => {
						//console.log(res);
						let {
							account,
							real_name,
							age,
							ele_signature_type,
							remark,
							sign_id,
							head_id,
							departments_id,
							role_id,
							head_img,
							sign_img,
							sex,
							id,
						} = res.data;
						this.form.account = account;
						this.form.name = real_name;
						this.form.age = age;
						this.form.role_id = role_id + "";
						this.form.sex = sex + "";
						this.sign_url = sign_img;
						this.head_url = head_img;
						this.form.remark = remark;
						this.form.departments_id = departments_id;
						this.form.id = id;
						this.form.sign_id = sign_id;
						this.form.head_id = head_id;
					});
				}
			},
			// 添加 / 编辑 管理员按钮
			addAadminBtn() {
				// 检验字段
				let isCode = true; //控制是否表单验证通过
				this.$refs.addAadmin.validate((valid) => {
					if (!valid) {
						this.$message({
							showClose: true,
							message: "温馨提醒，还有内容未填写",
							type: "warning",
						});
						isCode = false;
						return;
					}
				});
				//控制是是否结束请求
				if (!isCode) {
					return;
				}

				if (this.isAdminState === 1) {
					register(this.form).then((res) => {
						if (res.code === 400200) {
							this.addAdminBox = false;
							this.formReset();
							this.$message({
								message: "恭喜你，添加成功",
								type: "success",
							});
							this.loadListDepartData(this.page);
						}
					});
				} else {
					updateAdmin(this.form).then((res) => {
						if (res.code === 400200) {
							this.addAdminBox = false;
							this.formReset();
							this.$message({
								message: "恭喜你，修改成功",
								type: "success",
							});
							this.loadListDepartData(this.page);
						}
					});
				}
			},
			// 关闭添加管理员
			hideAddAdminBox() {
				this.formReset();
			},
			// 文件上传成功的狗子
			fileUploadApi(response, file, fileList, val) {
				if (val === 1) {
					this.form.head_id = response.data.id;
					this.head_url = URL.createObjectURL(file.raw);
				} else {
					this.form.sign_id = response.data.id;
					this.sign_url = URL.createObjectURL(file.raw);
				}
			},
			beforeAvatarUpload(file) {
				var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
				const extension =
					testmsg === "jpg" ||
					testmsg === "JPG" ||
					testmsg === "png" ||
					testmsg === "PNG" ||
					testmsg === "bpm" ||
					testmsg === "BPM";
				const isLt50M = file.size / 1024 / 1024 < 2;
				if (!extension) {
					this.$message({
						message: "上传图片只能是jpg / png / bpm格式!",
						type: "error",
					});
					return false;
				}
				//console.log(file);
				if (!isLt50M) {
					this.$message({
						message: "上传文件大小不能超过 2MB!",
						type: "error",
					});
					return false;
				}
				return extension || isLt50M;
			},
			// 团体量表数据请求
			async loadScaleData() {
				var res = await axios.get("/all_measure_classify");
				if (res.data.code === 400200) {
					this.scaleData = res.data.data;
					this.scaleData1 = res.data.data;
					// 所有量表名称
					res.data.data.forEach((v) => {
						v.status_measure.forEach((v) => {
							if (
								v.measure_title == "心理健康临床症状自评量表(SCL-90)" ||
								v.measure_title == "抑郁自评量表(SDS)" ||
								v.measure_title == "焦虑自评量表(SAS)" ||
								v.measure_title == "卡特尔十六种人格因素测验(16PF)" ||
								v.measure_title == "社会支持评定量表" ||
								v.measure_title == "MBTI职业性格测试" ||
								v.measure_title == "症状自评量表" ||
								v.measure_title == "测评一" ||
								v.measure_title == "测评二" ||
								v.measure_title == "测评三" ||
								v.measure_title == "测评四"
							) {
								this.scaleList.push(v);
							}
						});
					});
				} else {
					return;
				}
			},
			// 学校量表数据
			getSchool() {
				let data = {
					page: 1,
					size: 9999
				};
				loadMeasureList(data).then((res) => {
					if (!res) return;
					let {
						data
					} = res.data;
					this.options = data;
					// console.log(data, "llf2");
				});
			},
			//判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
			handleCheckAllChange(val) {
				var a = parseInt(val.target.value);
				if (val.target.name === "a") {
					var obj = {
						measure_id: a,
						show_result: "" || 0
					};
					var tmp = this.form.permitList.permit_measure.some(function(item) {
						return item.measure_id === a;
					});
					if (tmp) {
						this.form.permitList.permit_measure = this.form.permitList.permit_measure.filter(
							(v) => {
								return v.measure_id != val.target.value;
							}
						);
					} else {
						this.form.permitList.permit_measure.push(obj);
					}
				}

				if (val.target.name === "b") {
					var p = this.checkList.indexOf(a);
					var index = this.form.permitList.permit_measure.findIndex((item) => {
						return item.measure_id === a;
					});
					if (this.form.permitList.permit_measure[index] && index != -1) {
						if (this.form.permitList.permit_measure[index].show_result === 0) {
							this.form.permitList.permit_measure[index].show_result = 1;
						} else {
							this.form.permitList.permit_measure[index].show_result = 0;
						}
					}

					if (p === -1) {
						this.$message.error("请先选择该表单后，在选择此选项");
						this.checkList2 = this.checkList2.filter((v) => {
							return v != val.target.value;
						});
						return;
					}
				}
			},
			// 表单重置
			formReset() {
				this.form.type = 2;
				this.form.account = "";
				this.form.password = "";
				this.form.name = "";
				this.form.sex = "";
				this.form.age = "";
				this.form.signatureType = 1;
				this.form.remark = "";
				this.form.permitList.permit_review = "1";
				this.form.permitList.permit_measure = [];
				this.form.permitList.permit_index = "1";
				this.form.sign_id = "";
				this.form.head_id = "";
				this.form.departments_id = "";
				this.form.role_id = "";
				this.sign_url = "";
				this.head_url = "";
			},
			// 删除管理员
			delAadminList(row) {
				let {
					id,
					account
				} = row;

				this.$confirm("是否删除该管理员?", "提示", {
						confirmButtonText: "删除",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						delAadmin({
							id,
							account
						}).then((res) => {
							//console.log(res);
							if (res.code === 400200) {
								this.$message({
									message: "删除成功",
									type: "success",
								});
								this.loadListDepartData(this.page);
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			// 显示添加科室   /   编辑
			showAddDepartmentBox(row) {
				this.departmentTilte = "新增科室";
				this.addDepartment = true;
				this.isState = 1;
				if (row.id) {
					this.depForm.dId = row.id;
					this.isState = 0;
					this.departmentTilte = "修改科室信息";
					getDepartment({
						id: row.id
					}).then((res) => {
						let {
							name,
							permit
						} = res.data;
						this.depForm.depname = name;
						this.depForm.permit = permit;
					});
				}
			},
			// 确定添加科室按钮  /  编辑更新
			addDepartmentBtn() {
				// 检验字段
				let isCode = true; //控制是否表单验证通过
				this.$refs.addDep.validate((valid) => {
					if (!valid) {
						this.$message({
							showClose: true,
							message: "温馨提醒，还有内容未填写",
							type: "warning",
						});
						isCode = false;
						return;
					}
				});
				//控制是是否结束请求
				if (!isCode) {
					return;
				}
				if (this.isState === 1) {
					// 创建name字段
					this.depForm.name = this.depForm.depname;
					createdDepartment(this.depForm).then((res) => {
						if (res.code === 400200) {
							this.$message({
								message: "添加成功",
								type: "success",
							});
							this.addDepartment = false;
							this.loadListDepartData(this.page);
							this.addDrpReset();
						}
					});
				} else {
					let data = {
						departmentName: this.depForm.depname,
						parent_id: 0,
						path: 0,
						dId: this.depForm.dId,
						permit: this.depForm.permit,
						name: this.depForm.depname,
					};
					updateDepartment(data).then((res) => {
						if (res.code === 400200) {
							this.$message({
								message: "修改成功",
								type: "success",
							});
						}
						this.addDepartment = false;
						this.loadListDepartData(this.page);
						this.addDrpReset();
					});
				}
			},
			// 添加模态框数据重置
			addDrpReset() {
				this.depForm.depname = "";
				this.depForm.parent_id = 0;
				this.depForm.path = 0;
				this.depForm.permit.show_test_log = 1;
				this.depForm.permit.show_archives = 1;
				this.depForm.permit.edit_archives = 1;
				this.depForm.permit.sweep_qr_code = 0;
				this.depForm.permit.voice_reading = 0;
				this.depForm.permit.online_consulting = 0;
				this.depForm.permit.modify_option = 1;
				this.depForm.permit.allow_register = 1;
				this.depForm.permit.questionnaire_state = 1;
			},
			// 排序
			updateSort(row) {
				let {
					id,
					sort,
					name
				} = row;
				let data = {
					dId: id,
					sort,
					name,
					departmentName: name,
				};
				updateDepartment(data).then((res) => {
					if (res.code === 400200) {
						this.$message({
							message: "排序更新成功",
							type: "success",
						});
						this.loadListDepartData(this.page);
					}
				});
			},
			// 全选删除
			delDatas() {
				// 判断是否有数据
				if (this.multipleSelection.length <= 0) {
					this.$message({
						showClose: true,
						message: "请先勾选要删除的数据",
						type: "warning",
					});
					return;
				}
				//  提示 是否删除
				this.$confirm("是否删除已勾选的数据?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						let data = this.multipleSelection.toString();
						//console.log(data);

						deleteDepartment({
							id: data
						}).then((res) => {
							if (res.code === 400200) {
								this.$message({
									message: "删除成功",
									type: "success",
								});
								this.loadListDepartData(this.page);
							}
						});
					})
					.catch(() => {});
			},
			// 跳转-团体报告
			goDownpdfGroup() {
				if (this.groupMeasureId == "") {
					this.$message({
						showClose: true,
						message: "警告，请先选择量表",
						type: "warning",
					});
					return;
				}
				this.GroupBox = false;
				let routeData = this.$router.resolve({
					path: "/downpdfGroup",
					name: "detail",
					query: {
						measure_id: this.groupMeasureId,
						department_id: this.groupDepartmentId,
					},
				});
				window.open(routeData.href, "_blank");
			},
			// 跳转-团体报告
			goDownpdfGroup() {
				if (this.groupMeasureId == "") {
					this.$message({
						showClose: true,
						message: "警告，请先选择量表",
						type: "warning",
					});
					return;
				}
				this.GroupBox = false;
				let routeData = this.$router.resolve({
					path: "/downpdfGroup",
					name: "detail",
					query: {
						measure_id: this.groupMeasureId,
						department_id: this.groupDepartmentId,
					},
				});
				window.open(routeData.href, "_blank");
			},
			// 跳转-学校报告
			goDownpdfSchool() {
				if (this.schoolMeasureId.length === 0) {
					this.$message({
						showClose: true,
						message: "警告，请先选择量表",
						type: "warning",
					});
					return;
				}
				this.SchoolBox = false;
				let routeData = this.$router.resolve({
					path: "/downpdfSchool",
					name: "detail",
					query: {
						measure_id: this.schoolMeasureId,
						department_id: this.schoolDepartmentId,
						schoolName: this.schoolName,
					},
				});
				window.open(routeData.href, "_blank");
			},
			// 显示学校报告筛选框
			showSchoolBox(row) {
				this.schoolName = row.name;
				this.schoolList = [];
				for (const i in row.statistics_group_arr) {
					this.schoolList.push({
						measure_title: row.statistics_group_arr[i],
						id: Number(i)
					});
				}
				this.schoolDepartmentId = row.id;
				this.SchoolBox = true;
			},
			// 学校报告筛选框-重置
			schoolBoxReset() {
				this.schoolMeasureId = [];
				this.schoolDepartmentId = "";
			},
			// 显示团体报告筛选框
			showGroupBox(row) {
				this.groupDepartmentId = row.id;
				this.GroupBox = true;
			},
			// 团体报告筛选框-重置
			groupBoxReset() {
				this.groupMeasureId = "";
				this.groupDepartmentId = "";
			},
			// ====================
			rowExpand(e, a, f) {},
			//设置table中的扩展项，展开的id，此处我需要全部展开
			getExpends() {
				var Id = this.tableData.map((item) => item.id);
				this.expends = Id;
			},
			getRowKeys(row) {
				return row.id;
			},
		},
	};
</script>

<style lang="less">
	.tc-permit {
		width: 100%;
		min-width: 1300px;

		.header-tool {
			margin-top: 20px;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.del {
				width: 100px;
				margin-right: 20px;
				cursor: pointer;
			}

			.refresh {
				width: 100px;
				cursor: pointer;
			}

			.authorityimg {
				width: 130px;
				margin-right: 20px;
				cursor: pointer;

				a {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			img {
				width: 100%;
				pointer-events: none;
			}
		}

		//   排序
		.sort {
			border-radius: 5px;
			margin-top: 20px;
			height: 55px;
			background-color: rgb(#d9f2f7);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 10px;

			.el-divider--vertical {
				height: 2em;
			}

			.el-button {
				background: transparent;
				color: rgb(#044d5a);
				font-size: 15px;
				border: 1px solid rgb(182, 226, 235);

				&:hover {
					border: 1px solid rgb(105, 105, 105);
				}
			}

			.el-input {
				width: 130px;
				margin-right: 10px;
			}
		}

		//   表格
		.content {
			position: relative;
			height: calc(~"100% - 220px");
			min-height: 665px;
			margin-top: 20px;
			color: rgb(4, 87, 102);

			th {
				color: rgb(4, 87, 102);
				padding: 0;
				height: 40px;
				line-height: 40px;
				background: rgb(217, 242, 247);
			}

			td {
				padding: 0;
				height: 35px;
				line-height: 35px;
			}

			tr.el-table__row--striped td {
				background: rgb(234, 246, 248);
			}

			.el-button+.el-button {
				margin-left: 0px;
			}

			.el-button--mini {
				padding: 4px 4px;
				background: transparent;
				color: rgb(4, 87, 102);
				font-size: 14px;

				&:hover {
					border: 1px solid rgb(105, 105, 105);
				}
			}

			.no {
				color: green;
			}

			.off {
				color: red;
			}

			.el-input__inner {
				width: 50px;
				padding: 5px;
				text-align: center;
			}
		}

		// 页码
		.page {
			display: flex;
			justify-content: flex-end;
			margin-top: 10px;
		}

		// 头像
		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409eff;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 80px;
			height: 80px;
			line-height: 80px;
			text-align: center;
		}

		.avatar {
			width: 80px;
			height: 80px;
			display: block;
		}

		.el-dialog {
			min-width: 560px;

			.el-input__inner {
				width: 180px;
			}

			.table {
				// width: 850px;
				height: 300px;
				overflow: auto;
				border: 1px #cccccc solid;
				margin-bottom: 20px;

				.el-input__inner {
					width: 120px;
					padding-right: 20px;
				}

				table {
					// width: 848px;
					width: 100%;
					text-align: center;
					overflow: auto;

					tr,
					th {
						height: 20px;
						padding: 0;
						font-weight: 300;
						font-size: 14px;
					}

					tr {
						td {
							&:nth-child(1) {
								text-align: left;
							}
						}
					}

					th {
						height: 30px;
						font-size: 15px;
						background: rgb(224, 245, 250);
					}

					.title {
						background: rgb(224, 245, 250);
						text-align: left;
						font-weight: 700;
						font-size: 15px;
					}

					.el-checkbox__label {
						display: none;
					}
				}
			}
		}

		.GroupBox {
			.el-input__inner {
				width: 400px;
			}
		}
	}
</style>